import React from 'react'
import {
  CheckOutlined,
  ClockCircleOutlined,
  DislikeOutlined,
  ExceptionOutlined,
  HourglassOutlined,
  LoginOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  StopOutlined,
  SyncOutlined,
  UserAddOutlined,
} from '@ant-design/icons'

import { Can, useTranslation } from '@nic/commons'
import { Div, Flex, HorizontalLayout, PaperLayout, VerticalLayout } from '@nic/ui-comps'

import { IRegistrarInfo } from '../types'
import { AuthContext } from '@nic/kc-auth'
import { LayoutContext } from '../Context/LayoutContext'
import { Breadcrumb } from '../Components/Breadcrumb/Breadcrumb'
import { RequestCounter } from '../Components/Counters/RequestCounter'
import { NicScope } from '@nic/nic-api/types'
import { RequestsExpiringCounter } from '../Components/Counters/RequestsExpiringCounter'
import { Divider } from 'antd'

interface IDashboard {
  realm: NicScope
}

const DashboardColorMapping: any = {
  workingSection: {
    background: 'rgb(255, 185, 128)',
    activeIconColor: '#d98e06',
    disabledIconColor: '#f1daaf',
  },
  successSection: {
    background: 'rgb(151, 181, 82)',
    activeIconColor: 'white',
  },
  rejectOrFailureSection: {
    background: 'rgb(216, 122, 128)',
    activeIconColor: 'white',
  },
  processingExpiredSection: {
    background: 'rgb(208, 153, 157)',
    activeIconColor: 'white',
  },
  processingSection: {
    background: 'rgb(221, 203, 50)',
    activeIconColor: 'white',
  },
  searchSection: {
    background: 'rgb(115, 185, 188) ',
    activeIconColor: 'white',
  },
  initialSection: {
    background: 'rgb(141, 152, 179)',
    activeIconColor: 'white',
  },
  expiringSection: {
    background: 'rgb(255, 75, 75)',
    activeIconColor: 'white',
  },
}

interface MappingI {
  icons: Record<string, React.ReactNode>
  background: Record<string, string>
}

const PlayOrPause: Record<string, MappingI> = {
  registry: {
    icons: {
      pending: (
        <>
          <PauseCircleOutlined style={{ fontSize: '2.2rem', color: 'white' }} />
          {/*<TeamOutlined style={{ fontSize: '2.2rem', color: 'white' }} />*/}
        </>
      ),
      pendingAssigned: (
        <>
          <PauseCircleOutlined style={{ fontSize: '2.2rem', color: 'white' }} />
          <UserAddOutlined style={{ fontSize: '1.8rem', color: 'white' }} />
        </>
      ),
      registrarUpdated: <PlayCircleOutlined style={{ fontSize: '2.2rem', color: 'white' }} />,
      registrarUpdatedAssigned: (
        <>
          <PlayCircleOutlined style={{ fontSize: '2.2rem', color: 'white' }} />
          <UserAddOutlined style={{ fontSize: '1.8rem', color: 'white' }} />
        </>
      ),
    },
    background: {
      pending: 'rgb(249, 202, 124)',
      pendingAssigned: 'rgb(255, 165, 13)',
      registrarUpdated: 'rgb(96, 176, 247)',
      registrarUpdatedAssigned: 'rgb(24, 144, 255)',
    },
  },
  registrar: {
    icons: {
      pending: <PlayCircleOutlined style={{ fontSize: '2.2rem', color: 'white' }} />,
      registrarUpdated: <PauseCircleOutlined style={{ fontSize: '2.2rem', color: 'white' }} />,
      pendingAssigned: (
        <>
          <PlayCircleOutlined style={{ fontSize: '2.2rem', color: 'white' }} />
          <UserAddOutlined style={{ fontSize: '1.8rem', color: 'white' }} />
        </>
      ),
      registrarUpdatedAssigned: (
        <>
          <PauseCircleOutlined style={{ fontSize: '2.2rem', color: 'white' }} />
          <UserAddOutlined style={{ fontSize: '1.8rem', color: 'white' }} />
        </>
      ),
    },
    background: {
      registrarUpdated: 'rgb(249, 202, 124)',
      registrarUpdatedAssigned: 'rgb(255, 165, 13)',
      pending: 'rgb(96, 176, 247)',
      pendingAssigned: 'rgb(24, 144, 255)',
    },
  },
}

export const DashboardPage: React.FC<IDashboard> = (props) => {
  const { keycloak } = React.useContext(AuthContext)
  const t = useTranslation()
  const { realm } = props
  const dashboardTitle = 'Dashboard ' + t(`dashboard.${realm}`)
  const layout = React.useContext(LayoutContext)
  const isRegistrar = realm === 'registrar'
  React.useEffect(() => {
    layout.setLayoutElement(
      'BREADCRUMB',
      <Breadcrumb elements={[{ label: 'Dashboard', path: `/${realm}/dashboard` }]} />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const registrarInfo: IRegistrarInfo | undefined = keycloak?.tokenParsed as IRegistrarInfo

  /***
   * Assegna il valore della vista per le assegnazioni di richieste associate a quel utente/ruolo.
   * Se il valore è dato ed è compatibile con quello del ruolo allora viene associato altrimenti ne viene dato uno di default
   * @param value
   * @param role
   */

  return (
    <Flex direction={'column'} justify={'center'} width={'100%'}>
      <PaperLayout asCard={true}>
        <Div>{dashboardTitle}</Div>
      </PaperLayout>
      <VerticalLayout>
        <HorizontalLayout style={{ gap: 20, alignItems: 'stretch', width: '100%' }}>
          {/* SOLO PER ADMIN */}
          <Can I={'View'} a={'AllPendingRequest'}>
            <RequestCounter
              status={'pending'}
              assignmentView={{
                param: isRegistrar ? 'registrarAssignation' : 'registryAssignation',
                value: 'all',
              }}
              title={t('dashboard.status.pending')}
              icon={<>{PlayOrPause[realm].icons.pending}</>}
              registrarId={registrarInfo.registrarId}
              redirect={`/${realm}/requests?status=pending&view=expirationDays,referenceContact&${
                isRegistrar ? 'registrarAssignation' : 'registryAssignation'
              }=all`}
              scope={realm as NicScope}
              backgroundColor={PlayOrPause[realm].background.pending}
              className={'darwin'}
              tip={t('dashboard.tip.pending')}
            />
          </Can>
          <Can I={'View'} a={'AllRegistrarUpdated'}>
            <RequestCounter
              status={'registrarUpdated'}
              assignmentView={{
                param: isRegistrar ? 'registrarAssignation' : 'registryAssignation',
                value: 'all',
              }}
              title={t('dashboard.status.registrarUpdated')}
              icon={<>{PlayOrPause[realm].icons.registrarUpdated}</>}
              redirect={`/${realm}/requests?status=registrarUpdated&view=expirationDays,referenceContact&&${
                isRegistrar ? 'registrarAssignation' : 'registryAssignation'
              }=all`}
              scope={realm as NicScope}
              backgroundColor={PlayOrPause[realm].background.registrarUpdated}
              className={'darwin'}
              tip={t('dashboard.tip.registrarUpdated')}
            />
          </Can>
        </HorizontalLayout>
      </VerticalLayout>
      <VerticalLayout>
        <HorizontalLayout style={{ gap: 20, alignItems: 'stretch', width: '100%' }}>
          {/* ASSEGNATE A ME */}
          <Can I={'View'} a={'PendingRequest'}>
            <RequestCounter
              status={'pending'}
              assignmentView={{
                param: isRegistrar ? 'registrarAssignation' : 'registryAssignation',
                value: 'assignedToMe',
              }}
              title={t('dashboard.status.pendingAssigned')}
              icon={<>{PlayOrPause[realm].icons.pendingAssigned}</>}
              registrarId={registrarInfo.registrarId}
              redirect={`/${realm}/requests?status=pending&view=expirationDays,referenceContact&&${
                isRegistrar ? 'registrarAssignation' : 'registryAssignation'
              }=assignedToMe`}
              scope={realm as NicScope}
              backgroundColor={PlayOrPause[realm].background.pendingAssigned}
              className={'darwin'}
              tip={t('dashboard.tip.pending')}
            />
          </Can>

          <Can I={'View'} a={'RegistrarUpdatedRequest'}>
            <RequestCounter
              status={'registrarUpdated'}
              assignmentView={{
                param: isRegistrar ? 'registrarAssignation' : 'registryAssignation',
                value: 'assignedToMe',
              }}
              title={t('dashboard.status.registrarUpdatedAssigned')}
              icon={<>{PlayOrPause[realm].icons.registrarUpdatedAssigned}</>}
              redirect={`/${realm}/requests?status=registrarUpdated&view=expirationDays,referenceContact&${
                isRegistrar ? 'registrarAssignation' : 'registryAssignation'
              }=assignedToMe`}
              scope={realm as NicScope}
              backgroundColor={PlayOrPause[realm].background.registrarUpdatedAssigned}
              className={'darwin'}
              tip={t('dashboard.tip.registrarUpdated')}
            />
          </Can>
        </HorizontalLayout>
        <Divider />

        <HorizontalLayout style={{ gap: 20, alignItems: 'stretch', width: '100%' }}>
          <Can I={'View'} a={'ProcessingRequest'}>
            <RequestCounter
              status={'processing'}
              icon={
                <SyncOutlined
                  twoToneColor={'red'}
                  style={{
                    fontSize: '2.2rem',
                    color: `${DashboardColorMapping.processingSection.activeIconColor}`,
                  }}
                />
              }
              title={t('dashboard.status.processing')}
              redirect={`/${realm}/requests?status=processing`}
              scope={realm as NicScope}
              backgroundColor={DashboardColorMapping.processingSection.background}
              className={'darwin'}
              tip={t('dashboard.tip.processing')}
            />
          </Can>
          <Can I={'View'} a={'CompletedRequest'}>
            <RequestCounter
              status={'completed'}
              title={t('dashboard.status.completed')}
              icon={<CheckOutlined style={{ fontSize: '2.2rem', color: 'white' }} />}
              registrarId={registrarInfo.registrarId}
              redirect={`/${realm}/requests?status=completed`}
              scope={realm as NicScope}
              backgroundColor={DashboardColorMapping.successSection.background}
              className={'darwin'}
              tip={t('dashboard.tip.completed')}
            />
          </Can>

          <Can I={'View'} a={'ProcessingExpiredRequest'}>
            <RequestCounter
              status={'processingExpired'}
              icon={
                <HourglassOutlined
                  twoToneColor={'red'}
                  style={{
                    fontSize: '2.2rem',
                    color: `${DashboardColorMapping.processingExpiredSection.activeIconColor}`,
                  }}
                />
              }
              title={t('dashboard.status.processingExpired')}
              redirect={`/${realm}/requests?status=processingExpired`}
              backgroundColor={DashboardColorMapping.processingExpiredSection.background}
              className={'darwin'}
              scope={realm as NicScope}
              tip={t('dashboard.tip.processingExpired')}
            />
          </Can>

          <Can I={'View'} a={'ExpiredRequest'}>
            <RequestCounter
              status={'expired'}
              icon={
                <ClockCircleOutlined
                  twoToneColor={'red'}
                  style={{
                    fontSize: '2.2rem',
                    color: `${DashboardColorMapping.rejectOrFailureSection.activeIconColor}`,
                  }}
                />
              }
              title={t('dashboard.status.expired')}
              redirect={`/${realm}/requests?status=expired&view=expirationDays,referenceContact`}
              scope={realm as NicScope}
              backgroundColor={DashboardColorMapping.rejectOrFailureSection.background}
              className={'darwin'}
              tip={t('dashboard.tip.expired')}
            />
          </Can>
        </HorizontalLayout>
      </VerticalLayout>
      <Divider />
      <VerticalLayout>
        <HorizontalLayout style={{ gap: 20, alignItems: 'stretch', width: '100%' }}>
          <Can I={'View'} a={'RejectedRequest'}>
            <RequestCounter
              status={'rejected'}
              icon={
                <DislikeOutlined
                  twoToneColor={'red'}
                  style={{
                    fontSize: '2.2rem',
                    color: `${DashboardColorMapping.rejectOrFailureSection.activeIconColor}`,
                  }}
                />
              }
              title={t('dashboard.status.rejected')}
              redirect={`/${realm}/requests?status=rejected&view=expirationDays,referenceContact`}
              scope={realm as NicScope}
              backgroundColor={DashboardColorMapping.rejectOrFailureSection.background}
              className={'darwin'}
              tip={t('dashboard.tip.rejected')}
            />
          </Can>

          <Can I={'View'} a={'CancelledRequest'}>
            <RequestCounter
              status={'cancelled'}
              icon={
                <StopOutlined
                  twoToneColor={'red'}
                  style={{
                    fontSize: '2.2rem',
                    color: `${DashboardColorMapping.rejectOrFailureSection.activeIconColor}`,
                  }}
                />
              }
              title={t('dashboard.status.cancelled')}
              redirect={`/${realm}/requests?status=cancelled&view=expirationDays,referenceContact`}
              scope={realm as NicScope}
              backgroundColor={DashboardColorMapping.rejectOrFailureSection.background}
              className={'darwin'}
              tip={t('dashboard.tip.cancelled')}
            />
          </Can>

          <Can I={'View'} a={'SubmittedRequest'}>
            <RequestCounter
              status={'submitted'}
              title={t('dashboard.status.submitted')}
              icon={
                <LoginOutlined
                  twoToneColor={'gray'}
                  style={{
                    fontSize: '2.2rem',
                    color: `${DashboardColorMapping.initialSection.activeIconColor}`,
                  }}
                />
              }
              redirect={`/${realm}/requests?status=submitted&view=expirationDays,referenceContact`}
              scope={realm as NicScope}
              backgroundColor={DashboardColorMapping.initialSection.background}
              className={'darwin'}
              tip={t('dashboard.tip.submitted')}
            />
          </Can>
          <Can I={'View'} a={'ExpiringRequest'}>
            <RequestsExpiringCounter
              tip={t('dashboard.tip.expiring')}
              title={t('dashboard.status.expiring')}
              icon={
                <ExceptionOutlined
                  twoToneColor={'grey'}
                  style={{
                    fontSize: '2.2rem',
                    color: `${DashboardColorMapping.expiringSection.activeIconColor}`,
                  }}
                />
              }
              redirect={`/${realm}/requests_expiring`}
              scope={realm as NicScope}
              backgroundColor={DashboardColorMapping.expiringSection.background}
              className={'darwin'}
            />
          </Can>
        </HorizontalLayout>
      </VerticalLayout>
    </Flex>
  )
}
