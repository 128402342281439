import { QuestionCircleTwoTone } from '@ant-design/icons'
import { useTranslation } from '@nic/commons'
import { Div, Flex, HorizontalLayout, VerticalLayout } from '@nic/ui-comps'
import { Alert, Button, Collapse, Divider, Modal, Space } from 'antd'
import { useContext, useState } from 'react'
import { ContactsContext } from '../../../Providers/ContactsProvider'
import { VariationContext } from '../../../Providers/VariationProvider'
import { RequestContext } from '../../../Contexts/RequestContext'

const { Panel } = Collapse

function ModalDescription(props: { t: any }) {
  const {
    state: { toDelete, toHold },
  } = useContext(ContactsContext)
  const t = useTranslation()
  const { stateReqDomains } = useContext(RequestContext)
  const hasContactToDelete = toDelete?.page?.totalElements > 0
  const hasContactToHold = toHold?.page?.totalElements > 0
  const contactToDeleteList = toDelete?.elements?.map((c) => c.contactId)
  const contactToHoldList = toHold?.elements?.map((c) => c.contactId)
  const domainToDeleteList = stateReqDomains?.data?.elements.filter((r) =>
    contactToDeleteList.includes(r.registrarContactId)
  )
  const domainToHoldList = stateReqDomains?.data?.elements.filter((r) =>
    contactToHoldList.includes(r.registrarContactId)
  )
  const handleDeleteWarningMessage = () => {
    if (hasContactToDelete)
      return (
        <>
          <Divider />
          <Alert
            // message="Attenzione"
            description={
              <>
                {t('message.info.deleteWarning.message')}
                <Collapse style={{ backgroundColor: '#FFFBE6' }} bordered={false}>
                  <Panel
                    header={
                      <>
                        <b>{toDelete?.page?.totalElements}</b>{' '}
                        {t('message.info.deleteWarning.contacts')}
                      </>
                    }
                    key="1"
                  >
                    <p>
                      <ul>
                        {toDelete?.elements.map((c) => (
                          <li>
                            {c.name} ({c.contactId})
                          </li>
                        ))}
                      </ul>
                    </p>
                  </Panel>
                  <Panel
                    header={
                      <>
                        <b>{domainToDeleteList?.length}</b>{' '}
                        {t('message.info.deleteWarning.domains')}
                      </>
                    }
                    key="2"
                  >
                    <p>
                      <ul>
                        {domainToDeleteList?.map((r) => (
                          <li>
                            {r.domain} ({r.registrarContactId})
                          </li>
                        ))}
                      </ul>
                    </p>
                  </Panel>
                </Collapse>
              </>
            }
            type={'warning'}
            // showIcon
          />
        </>
      )
  }

  const handleHoldWarningMessage = () => {
    if (hasContactToHold)
      return (
        <>
          <Divider />
          <Alert
            // message="Attenzione"
            description={
              <>
                {t('message.info.holdWarning.message')}
                <Collapse style={{ backgroundColor: '#FFFBE6' }} bordered={false}>
                  <Panel
                    header={
                      <>
                        <b>{toHold?.page?.totalElements}</b>{' '}
                        {t('message.info.holdWarning.contacts')}
                      </>
                    }
                    key="1"
                  >
                    <p>
                      <ul>
                        {toHold?.elements.map((c) => (
                          <li>
                            {c.name} ({c.contactId})
                          </li>
                        ))}
                      </ul>
                    </p>
                  </Panel>
                  <Panel
                    header={
                      <>
                        <b>{domainToHoldList?.length}</b> {t('message.info.holdWarning.domains')}
                      </>
                    }
                    key="2"
                  >
                    <p>
                      <ul>
                        {domainToHoldList?.map((r) => (
                          <li>
                            {r.domain} ({r.registrarContactId})
                          </li>
                        ))}
                      </ul>
                    </p>
                  </Panel>
                </Collapse>
              </>
            }
            type={'warning'}
            // showIcon
          />
        </>
      )
  }

  return (
    <Flex direction={'row'} justify={'space-around'}>
      <VerticalLayout
        style={{
          alignItems: 'stretch',
          justifyContent: 'flex-start',
          width: '450px',
        }}
      >
        <HorizontalLayout
          style={{
            justifyContent: 'flex-start',
            gap: 10,
            // border: '1px solid black',
            padding: 10,
          }}
        >
          <QuestionCircleTwoTone style={{ fontSize: '25pt' }} />
          <Div fontSize={'1.2rem'}>{props.t}</Div>
        </HorizontalLayout>{' '}
        <Div>{handleDeleteWarningMessage()}</Div>
        <Div>{handleHoldWarningMessage()}</Div>
      </VerticalLayout>
    </Flex>
  )
}

export function VariationProposalButtons(props: {
  onCancel: () => void
  onPrev: () => void
  onNext: () => void

  disabledPrev?: boolean
  disabledNext?: boolean
}) {
  const FIRST = 0
  const LAST = 4
  const t = useTranslation()
  const { dispatch, state } = useContext(VariationContext)

  const handleOnCancel = () => {
    props.onCancel()
    dispatch({ type: 'EDIT_OFF' })
  }

  const handleNext = () => {
    props.onNext()
  }

  const handlePrev = () => {
    props.onPrev()
  }

  const [confirmModal, setConfirmModal] = useState(false)

  return (
    <Space>
      {state.step > FIRST && state.step < LAST && (
        <Button onClick={handlePrev} disabled={props.disabledPrev}>
          {t('Previous')}
        </Button>
      )}
      {state.step < LAST - 1 && (
        <Button onClick={handleNext} disabled={props.disabledNext} type={'primary'}>
          {t('Next')}
        </Button>
      )}
      {state.step === LAST - 1 && (
        <>
          <Button
            onClick={() => setConfirmModal(true)}
            disabled={props.disabledNext}
            type={'primary'}
          >
            {t('Done')}
          </Button>
          <Modal
            title={t('message.info.confirmChanges.title')}
            visible={confirmModal}
            onOk={handleNext}
            okText={t('button.ConfirmSend')}
            cancelText={t('Cancel')}
            onCancel={() => {
              setConfirmModal(false)
            }}
          >
            <ModalDescription t={t('message.info.confirmChanges.message')} />
          </Modal>
        </>
      )}
      {state.step < LAST && <Button onClick={handleOnCancel}>{t('Cancel')}</Button>}
    </Space>
  )
}
