import { Darwin, Domains } from '@nic/nic-api/types'
import { Flex } from '@nic/ui-comps'
import { Descriptions } from 'antd'
import React from 'react'
import { camelToDash, useTranslation } from '@nic/commons'
import { FactoryContactCard } from '../../../../../DataDisplay/RegistrantContactCards/FactoryContactCard'
import { contactAnomaliesUpdatesBuilder } from '../../../../../RegistrantLoader/helpers'
import { DocumentList } from '../../../../../Documents/DocumentList/DocumentList'
import { RenderDocumentItem } from '../../../../../Documents/RenderDocumentItem/RenderDocumentItem'

export interface ConfirmationStepViewProp {
  // TODO da eliminare asap
  values: { elements: any[]; noChanges: boolean }
  changes?: Darwin.ContactUpdate
  anomalies?: Darwin.Anomalies
  referenceContact?: Domains.Contact
  toHold: string[]
  toUpdate: string[]
  toDelete: string[]
  // TODO capire se  calcolare i domini  e come
  domainsToHold: string[]
  domainsToDelete: string[]

  documentList: Darwin.DarwinUploadedFileResponse[]
}

/**
 * Visualizza i dati che sono stati inseriti durante gli step del wizard di modifica proposta
 *
 *
 * @param props
 * @constructor
 */
export function ConfirmationStepView(props: ConfirmationStepViewProp) {
  const t = useTranslation()

  return (
    <Flex
      direction={'column'}
      className={camelToDash(ConfirmationStepView.name)}
      style={{
        border: '1px solid #DBDBDB',
        padding: '10px',
        rowGap: '50px',
        justifyContent: 'center',
      }}
    >
      {/* Per quanto riguarda cosa mostra in questa card va detto:
          - che si possono far vedere solo le vere modifiche come accade ora quindi facendo un diff  tra changes e referenceData
          - che si può fare vedere direttamente tutto changes che contiene le vere modifiche inviate al registro ma
          che possono combaciare con reference se non modificate
          - che si può popolare anche anomalies che indicherebbe in più anche la descrizione dell'anomalia
      */}
      {props.referenceContact && (
        <FactoryContactCard
          type={'edited'}
          data={contactAnomaliesUpdatesBuilder(props.referenceContact, props.changes, [])}
          loading={false}
          error={undefined}
        />
      )}
      <Descriptions
        title={t('confirmationStepView.associatedContacts.title')}
        layout={'horizontal'}
      >
        <Descriptions.Item label={t('confirmationStepView.associatedContacts.label1')}>
          <b>{props.toUpdate.length}</b>
        </Descriptions.Item>
        <Descriptions.Item label={t('confirmationStepView.associatedContacts.label2')}>
          <b>{props.toHold.length}</b>
        </Descriptions.Item>
        <Descriptions.Item label={t('confirmationStepView.associatedContacts.label3')}>
          <b>{props.toDelete.length}</b>
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title={t('confirmationStepView.documents.title')}>
        <Descriptions.Item>
          <DocumentList
            scope={'registrar'}
            uploadedDocumentList={props.documentList}
            renderDocumentItem={(selected) => RenderDocumentItem(selected, 'registrar')}
          />
        </Descriptions.Item>
      </Descriptions>
    </Flex>
  )
}
