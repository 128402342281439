import { faEdit } from '@fortawesome/free-solid-svg-icons'
import React, { useContext } from 'react'
import { FormInstance, TabPaneProps } from 'antd'
import { useTranslation } from '@nic/commons'
import { PaperTitle } from '../../../Layout/PaperTitle'
import { TabTitle } from '../../../Various/TabTitle/TabTitle'
import { RequestContext } from '../../../Contexts/RequestContext'
import { VariationContext } from '../../../Providers/VariationProvider'
import { VariationProposalSteps } from './Steps/VariationProposalSteps'
import { VariationRegistrantController } from './ContentStep/VariationRegistrantStep/VariationRegistrantStep'
import { ContactSelection } from './ContentStep/ContactSelectionStep'
import { Div, Flex, VerticalLayout } from '@nic/ui-comps'
import { ConfirmationStep } from './ContentStep/ConfirmationStep/ConfirmationStep'
import { useForm } from 'antd/lib/form/Form'
import { CustomTabPane } from '../CustomTabPane'
import {
  RequestPostContactUpdateService,
  RequestPostContactUpdateValidationService,
  RequestPostProcessService,
} from '@nic/nic-api'
import { ResultStep } from './ContentStep/ResultStep/ResultStep'
import { ContactsContext } from '../../../Providers/ContactsProvider'
import { DocumentStep } from './ContentStep/DocumentStep/DocumentStep'
import { Darwin, NicScope } from '@nic/nic-api/types'
import { AxiosError } from 'axios'
import { ReferenceContext } from '../../../Providers/ReferenceProvider'
import { useScopeInfo } from '../../../Hooks/useScopeInfo'
import { IContactUpdateContactData } from '../../../../../../nic-react-monorepo/packages/nic-api/types/Darwin/types'
import { VariationProposalButtons } from './VariationProposalButtons'

interface TabEditProp extends TabPaneProps {
  disabled?: boolean
  onCancel?: () => void
}

/***
 * Componente che si occupa di definire il comportamento del Wizard di proposta modifica dei dati contatti associati
 * alla richiesta segnalata al registrar.
 *
 * Contiene la logica per visualizzare gli steps che caratterizzano il Wizard.
 * Contiene la logica delle operazioni principali che definiscono lo Step corrente.
 *
 * @param props
 * @constructor
 */
export function EditTab(props: TabEditProp) {
  const [form] = useForm()
  const [saveChanges, setSaveChanges] = React.useState(true)
  const [managed, setManaged] = React.useState(false)
  const t = useTranslation()
  const requestCtx = useContext(RequestContext)
  const { scope } = useScopeInfo()

  const {
    state: { step, edit, changes, hasRequiredDocs },
    dispatch,
  } = useContext(VariationContext)

  const { stateReferenceDarwin } = useContext(ReferenceContext)
  const {
    state: { toDo },
  } = useContext(ContactsContext)

  const contactsToUpdate = React.useMemo(
    () => toDo.elements.map((c) => c.registrarContactId),
    [toDo]
  )
  // const contactsToDelete = React.useMemo(
  //   () => toDelete.elements.map((c) => c.registrarContactId),
  //   [toDelete]
  // );
  // const contactsToHold = React.useMemo(
  //   () => toHold.elements.map((c) => c.registrarContactId),
  //   [toHold]
  // );

  /*
   * se il campo edit della proposta di modifica viene cambiato allora i campi scritti vengono resettati
   */
  React.useEffect(() => {
    form.resetFields()
  }, [edit, form])

  const hasDoc =
    requestCtx.stateReqById.data?.requiredDocuments?.documents &&
    requestCtx.stateReqById.data?.requiredDocuments?.documents.length > 0

  const hasContactsToUpdate = toDo.elements.length > 0

  const handleOnClose = () => {
    handleOnCancel()
    requestCtx.reload && requestCtx.reload()
  }

  const handleOnCancel = () => {
    dispatch({ type: 'EDIT_OFF' })
    props.onCancel && props.onCancel()
  }

  const handleOnPrev = () => {
    switch (step) {
      // case 1:
      //   if (hasContactsToUpdate) {
      //     form.validateFields().then(() => dispatch({ type: 'PREV_STEP' }))
      //   }
      //   dispatch({ type: 'PREV_STEP' })
      //   break
      case 3:
        if (!hasContactsToUpdate || !hasDoc) {
          dispatch({ type: 'PREV_STEP' })
        }
        dispatch({ type: 'PREV_STEP' })
        break
      default:
        dispatch({ type: 'PREV_STEP' })
    }
  }

  /**
   * Gestore delle azioni compiute quando si clicca su "successivo"(next)
   */
  const handleOnNext = async () => {
    //console.log('Step: ', step)
    const referenceContactData = stateReferenceDarwin.data
    if (step === 0) {
      dispatch({ type: 'NEXT_STEP' })
      // Prepara lo step successivo con i valori iniziali del contatto Reference (Prescelto) popolando
      // l'oggetto changes del VariationProvider
      dispatch({
        type: 'SET_CHANGES_INIT',
        payload: {
          contactData: referenceContactData as IContactUpdateContactData,
          requestId: requestCtx.id,
        },
      })
    } else if (step === 1) {
      // Se non ci sono contatti da aggiornare salta lo step dei documenti
      if (!hasContactsToUpdate) {
        dispatch({ type: 'NEXT_STEP' })
        dispatch({ type: 'NEXT_STEP' })
      } else {
        onRegistrantVariationValidation(
          form,
          requestCtx.id,
          scope,
          changes,
          () => dispatch({ type: 'SET_CHANGES_LOAD' }),
          (values) => {
            dispatch({ type: 'SET_CHANGES_SUCCEED', payload: values })
            dispatch({ type: 'NEXT_STEP' })
            // Se non ci sono documenti richiesti salta lo step dei documenti
            if (!hasDoc) {
              dispatch({ type: 'NEXT_STEP' })
            }
          },
          (err, values?) => {
            dispatch({
              type: 'SET_CHANGES_FAILED',
              payload: { error: err, changes: values },
            })
          }
        )
      }
    } else if (step === 3) {
      const validatedValues = await form.validateFields()
      // Aggiunto validateValues per recuperare il messaggio da parte del Registrar o Registro
      // _changes procede a recuperare il valore di changes e ci aggiunge quello del 'message' preso dalla form
      const _changes = { ...changes, ...validatedValues }
      dispatch({ type: 'SUBMIT_VARIATION' })
      try {
        const resp = await onConfirmRegistrantVariation(
          requestCtx.id,
          contactsToUpdate,
          _changes,
          scope
        )
        dispatch({ type: 'VARIATION_SUCCEED', payload: resp })
      } catch (e: any) {
        dispatch({ type: 'VARIATION_FAILED', payload: e })
      }
      dispatch({ type: 'NEXT_STEP' })
    } else {
      dispatch({ type: 'NEXT_STEP' })
    }
  }
  return (
    <CustomTabPane
      tab={
        <TabTitle
          iconType={faEdit}
          text={t(scope === 'registry' ? 'tabEdit.titleRegistry' : 'tabEdit.title')}
        />
      }
      key={props.tabKey}
      disabled={!edit}
      divStyle={{ backgroundColor: 'white', margin: '0 15px 15px 15px' }}
    >
      <VerticalLayout style={{ alignItems: 'center' }}>
        <Flex
          direction={'column'}
          justify={'flex-start'}
          align={'flex-start'}
          width={'100%'}
          style={{ marginBottom: '20px' }}
        >
          <PaperTitle
            showIcon
            title={t(scope === 'registry' ? 'variationProposal' : 'variationProposal.title')}
            style={{ width: '100%' }}
          />
          <Div fontSize={'1.1rem'} style={{ alignSelf: 'center' }}>
            {t(
              scope === 'registry'
                ? 'variationProposal.descriptionRegistry'
                : 'variationProposal.description'
            )}
          </Div>
        </Flex>
        <Div
          padding={'20px 20px 20px 20px'}
          style={{
            borderTop: '1px solid #F0F0F0',
            borderBottom: '3px solid #F0F0F0',
            width: '90%',
            marginBottom: '30px',
          }}
        >
          <VariationProposalSteps />
        </Div>
        <Div
          className={'content-step-tab'}
          style={{
            width: '88%',
          }}
        >
          {step === 0 && <ContactSelection />}
          {/*{step === 1 && <VariationRegistrantStep form={form} />}*/}
          {step === 1 && <VariationRegistrantController form={form} />}
          {step === 2 && <DocumentStep />}
          {step === 3 && (
            <ConfirmationStep
              handleChanges={(c: boolean) => {
                setSaveChanges(c)
                setManaged(true)
              }}
              managed={managed}
              form={form}
            />
          )}
          {step === 4 && <ResultStep onClose={handleOnClose} />}

          <Div textAlign={'center'} margin={'20px'}>
            <VariationProposalButtons
              onCancel={handleOnCancel}
              onNext={handleOnNext}
              onPrev={handleOnPrev}
              disabledNext={
                (step === 2 && !hasRequiredDocs) ||
                (step === 3 && !saveChanges) ||
                (step === 3 && !managed)
              }
            />
          </Div>
        </Div>
      </VerticalLayout>
    </CustomTabPane>
  )
}

/**
 * Funzione che permette di validare i dati della form per la modifica dei dati del contatto
 * prescelto prima di procedere alla submit vera e propria.
 *
 * - recupera i dati presenti nell'oggetto changes presente im VariationProvider e li unisce a quelli risultati
 * dalle modifiche della form dopo averli formattati ed essere compatibili con il tipo Darwin.ContactUpdate
 *
 * - una volta ottenuto le modifiche queste vengono VALIDATE tramite il servizio RequestPostContactUpdateValidationService
 *
 * - se ok si procede, se ko si segnalano gli errori
 *
 * @param form
 * @param requestId
 * @param scope
 * @param previousChanges
 * @param onValidateFormSucceeded
 * @param onValidateForm
 * @param onValidateFormFailed
 */
async function onRegistrantVariationValidation(
  form: FormInstance,
  requestId: number,
  scope: NicScope,
  previousChanges: Darwin.ContactUpdate,
  onValidateForm: (values: any) => void,
  onValidateFormSucceeded: (values: any) => void,
  onValidateFormFailed: (error: AxiosError, values?: any) => void
) {
  try {
    let validatedValues = await form.validateFields()
    validatedValues.streets = validatedValues.streets.filter((s: string) => s !== undefined)

    if (validatedValues.registrant.entityType.value) {
      validatedValues.registrant.entityType = validatedValues.registrant.entityType.value
    }

    // validatedValues =  {...validatedValues, consentForPublish: validatedValues.consentForPublish.toString()}

    const contactDateUpdateValidated = { contactData: validatedValues }

    const mergedChanges = {
      ...(previousChanges as Darwin.ContactUpdate),
      ...contactDateUpdateValidated,
      requestId,
    }

    // console.log(
    //   "onRegistrantVariationValidation",
    //   mergedChanges,
    //   validatedValues
    // );
    onValidateForm(mergedChanges)
    if (validatedValues) {
      await RequestPostContactUpdateValidationService(mergedChanges, scope)
      onValidateFormSucceeded(mergedChanges)
    }
  } catch (e: any) {
    if (e?.errorFields?.length > 0) {
      onValidateFormFailed({
        message: 'malformed_fields',
        response: { status: 4000 },
      } as AxiosError)
    } else {
      const axiosError = e as AxiosError
      onValidateFormFailed(axiosError)
    }
  }
}

async function onConfirmRegistrantVariation(
  requestId: number,
  toDo: string[],
  changes: Darwin.ContactUpdate,
  scope: NicScope
) {
  if (toDo.length > 0) {
    return RequestPostContactUpdateService(changes, scope)
  } else {
    return RequestPostProcessService(requestId)
  } // sostituisce il valore la proprietà regcode con registrant.regcode
}
