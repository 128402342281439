import { useTranslation } from '@nic/commons'
import { Darwin } from '@nic/nic-api/types'
import { IAnomaly } from '@nic/nic-api/types/Darwin/types'
import { Div, PaperLayout } from '@nic/ui-comps'
import { Alert, Form, FormInstance, Input } from 'antd'
import { isEqual } from 'lodash'
import React, { useContext } from 'react'
import { RequestContext } from '../../../../../Contexts/RequestContext'
import { PaperTitle } from '../../../../../Layout/PaperTitle'
import { ContactsContext } from '../../../../../Providers/ContactsProvider'
import { ReferenceContext } from '../../../../../Providers/ReferenceProvider'
import { VariationContext } from '../../../../../Providers/VariationProvider'
import { PaperLayoutTitled } from '../../../../../Various/PaperLayoutTitled/PaperLayoutTitled'
import { ConfirmationStepView } from './ConfirmationStepView'
import { DescriptionWarningNoChanges } from './DescriptionWarningNoChanges'
import { documentUploadFile2DarwinUploadedFileResponse } from './utils'
import { useScopeInfo } from '../../../../../Hooks/useScopeInfo'

const { TextArea } = Input

export function ConfirmationStep(props: {
  managed: boolean
  handleChanges?: (c: boolean) => void
  form: FormInstance
}) {
  const { stateReferenceDarwin } = useContext(ReferenceContext)
  const { state: stateVariation } = useContext(VariationContext)
  const { stateReqAnomalies } = useContext(RequestContext)
  const { scope } = useScopeInfo()
  const { documents } = stateVariation
  const {
    state: { toHold, toDelete, toDo },
  } = useContext(ContactsContext)
  const [, setNote] = React.useState<string>()
  const [values, setValues] = React.useState<any>({
    elements: [],
    noChanges: true,
  })
  const [unmodifiedAnomalies, setUnmodifiedAnomalues] = React.useState<string[]>([])
  const [read, setRead] = React.useState<boolean>(false)

  const t = useTranslation()

  const contactsToUpdate = React.useMemo(
    () => toDo.elements.map((c) => c.registrarContactId),
    [toDo]
  )
  const contactsToDelete = React.useMemo(
    () => toDelete.elements.map((c) => c.registrarContactId),
    [toDelete]
  )
  const contactsToHold = React.useMemo(
    () => toHold.elements.map((c) => c.registrarContactId),
    [toHold]
  )

  const checkAnomaliesChanges = (
    changes: Record<string, boolean>,
    anomalies?: Darwin.Anomalies
  ) => {
    let res: string[] = []
    if (anomalies !== undefined) {
      anomalies.forEach((a: IAnomaly) => {
        switch (a.field) {
          case 'address':
            // Campo address in realtà è composto da
            // streets, city, state, country, postalcode
            const subFields = ['streets', 'city', 'state', 'postalCode']
            let atLeastOne = false
            subFields.forEach((f: string) => {
              atLeastOne = atLeastOne || changes[f]
            })

            if (!atLeastOne) {
              res.push(a.field)
            }

            break
          case 'regcode':
            if (!changes[a.field] && !changes.org) {
              res.push(a.field)
            }
            break
          case 'org':
            if (!changes[a.field] && !changes.regcode) {
              res.push(a.field)
            }
            break
          default:
            if (!changes[a.field]) {
              res.push(a.field)
            }
            break
        }
      })
    }
    return res
  }

  // TODO avento applicato un componente (cioè EditedRegistrantCard) e non più
  // la card che mostrava le differenze affiancate, questa parte potra essere omessa
  // appena deciso quale delle due compoente verrà usata
  React.useEffect(() => {
    const v = Object.entries(stateVariation?.changes?.contactData).reduce(
      (previous: { elements: any[]; noChanges: boolean }, current) => {
        const originaleValue =
          // @ts-ignore
          (stateReferenceDarwin.data &&
            // @ts-ignore
            stateReferenceDarwin.data[current[0]]) ??
          // @ts-ignore
          stateReferenceDarwin.data?.registrant[current[0]]

        const isDifferent = Array.isArray(current[1])
          ? !isEqual(current[1], originaleValue)
          : current[1] !== originaleValue
        return {
          elements: [
            ...previous.elements,
            {
              key: current[0],
              modifiedValue: current[1],
              isDifferent: isDifferent,
              originalValue: originaleValue,
            },
          ],
          noChanges: previous.noChanges && !isDifferent,
        }
      },
      { elements: [], noChanges: true }
    )

    const fieldMap: Record<string, boolean> = {}

    v.elements.forEach((e: any) => {
      //console.log('key: ', e.key)
      if (e.key === 'registrant') {
        fieldMap.regcode = e.modifiedValue.regcode !== e.originalValue.regcode
      } else {
        fieldMap[e.key] = e.isDifferent
      }
    })

    const modAnomalies: string[] = checkAnomaliesChanges(fieldMap, stateReqAnomalies.data)

    setUnmodifiedAnomalues(modAnomalies)

    setValues(v)
    /* Se non ci sono modifiche e il vettore anomalie è ancora pieno mostra warning */
    if (
      (v.noChanges || modAnomalies.length > 0) &&
      props.handleChanges !== undefined &&
      !props.managed
    ) {
      props.handleChanges(false)
      /* Se il vettore anomalie è vuoto allora sblocca pulsante FATTO */
    } else if (modAnomalies.length === 0 && props.handleChanges !== undefined) {
      props.handleChanges(true)
    }
  }, [stateVariation.changes.contactData, stateReferenceDarwin, props, stateReqAnomalies.data])

  const variationDocuments = documentUploadFile2DarwinUploadedFileResponse(documents)
  //console.log('Values: ', values, values.noChanges)
  return (
    <PaperLayout>
      <PaperTitle showIcon title={t('confirmationStepView.title')} />
      <Div padding={'10px'}>
        <Alert
          type={'info'}
          message={t(
            scope === 'registry' ? 'message.info.changesRegistry' : 'message.info.changes'
          )}
          showIcon
          style={{ marginBottom: 20 }}
        />
        {values.elements && (
          <ConfirmationStepView
            values={values}
            toHold={contactsToHold}
            toDelete={contactsToDelete}
            toUpdate={contactsToUpdate}
            referenceContact={stateReferenceDarwin.data}
            //TODO da aggiornare
            domainsToDelete={[]}
            domainsToHold={[]}
            documentList={variationDocuments}
            changes={stateVariation.changes}
            anomalies={stateReqAnomalies.data}
          />
        )}
        <PaperLayoutTitled title={t('confirmationStepView.message.title')}>
          <Form form={props.form}>
            <Form.Item name={'message'}>
              <TextArea
                rows={4}
                placeholder={t('confirmationStepView.message.placeholder')}
                maxLength={t('confirmationStepView.message.maxLength')}
                onChange={(e) => setNote(e.target.value)}
              />
            </Form.Item>
          </Form>
        </PaperLayoutTitled>
        {
          // TODO Trasfomare in componente e rivedere cosa passare per eliminare tutto la parte che sta sotto UseEffect
          // che è al momento eliminabile.
          // Bug: quando viene modificato l'org non va segnalata come anomalia il regcode e viceversa.
          (values.noChanges || unmodifiedAnomalies.length > 0) && (
            <>
              <br />
              <Alert
                message={
                  <Div fontSize={'1.1rem'} fontWeight={'700'}>
                    {t('message.warning.title')}
                  </Div>
                }
                description={
                  <DescriptionWarningNoChanges
                    values={values}
                    title={t('message.warning.noChanges')}
                    unmodifiedAnomalies={unmodifiedAnomalies}
                    description1={t('message.warning.unchangedAnomaly')}
                    disable={read}
                    element={(a: string, i: number) => (
                      <Div fontWeight={'700'} marginLeft={'10px'} key={i}>
                        {t(`label.${a}`)}
                      </Div>
                    )}
                    description2={t('message.warning.areUSure')}
                    onClick={() => {
                      if (props.handleChanges !== undefined) {
                        setRead(true)
                        props.handleChanges(true)
                      }
                    }}
                    submitButtonText={t('button.ConfirmSend')}
                  />
                }
                type="warning"
                showIcon
              />
            </>
          )
        }
      </Div>
    </PaperLayout>
  )
}
