import { Button, Input, List, Space, Upload, UploadProps } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import React from 'react'
import { UploadFile } from 'antd/lib/upload/interface'
import { Darwin } from '@nic/nic-api/types'

export interface DocumentUploadFile<T> {
  docType: Darwin.DocType
  fileList?: UploadFile<T>[]
}

export interface DocumentUploadProp<T> {
  docs: DocumentUploadFile<T>[]
  header?: React.ReactNode
  footer?: React.ReactNode
  renderUploadButtonLabel?: (docType: string) => React.ReactNode
  renderUploadLabel?: (docType: string) => React.ReactNode
  uploadProps: UploadProps
  customRequest?: (options: any, note: string, type: Darwin.DocType) => void
  onPreview?: (file: UploadFile<T>) => void
  onRemove?: (file: UploadFile<T>) => void | boolean | Promise<void | boolean>
  beforeUpload?: (file: UploadFile<T>) => void | boolean | Promise<void | boolean>
}

export function DocumentUpload(props: DocumentUploadProp<any>) {
  return (
    <List
      size="large"
      header={props.header}
      footer={props.footer}
      bordered
      dataSource={props.docs}
      renderItem={(item) => (
        <List.Item>
          <DocumentRow
            {...props}
            uploadProps={props.uploadProps}
            documentUploadFile={item}
            renderButtonLabel={props.renderUploadButtonLabel}
            renderLabel={props.renderUploadLabel}
            customRequest={props.customRequest}
            onPreview={props.onPreview}
            onRemove={props.onRemove}
          />
        </List.Item>
      )}
    />
  )
}

function DocumentRow(
  props: {
    documentUploadFile: DocumentUploadFile<any>
    renderButtonLabel?: (docType: string) => React.ReactNode
    renderLabel?: (docType: string) => React.ReactNode
    uploadProps: UploadProps
    customRequest?: (options: any, note: string, type: Darwin.DocType) => void
    onPreview?: (file: UploadFile<any>) => void
    onRemove?: (file: UploadFile<any>) => void | boolean | Promise<void | boolean>
  } & DocumentUploadProp<any>
) {
  // console.log('documentRow', props)
  const [note, setNote] = React.useState('')
  const _uploadProps: UploadProps = {
    name: 'file',
    ...props.uploadProps,
    defaultFileList: props.documentUploadFile.fileList,
    action: () => {
      return new Promise((resolve) => resolve('Promise della ACtion => ciao'))
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.debug('onChange Uploading', JSON.stringify(info))
      }
      if (info.file.status === 'done') {
        // message.success(`${info.file.name} file uploaded successfully`)
        console.debug('onChange done', JSON.stringify(info))
      } else if (info.file.status === 'error') {
        console.debug('onChange error', JSON.stringify(info))

        // message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  const uploadedFile: UploadFile | undefined =
    props.documentUploadFile.fileList && props.documentUploadFile.fileList?.length > 0
      ? props.documentUploadFile.fileList[0]
      : undefined

  // const isDone = uploadedFile && uploadedFile.status === 'done'

  return (
    <Space direction={'vertical'}>
      <div>{props.renderLabel && props.renderLabel(props.documentUploadFile.docType)}</div>
      <Input
        // disabled={isDone}
        onChange={(e) => setNote(e.target.value)}
        placeholder={(uploadedFile?.response && uploadedFile?.response.note) || 'Aggiungere note'}
      />
      <Upload
        {...props}
        beforeUpload={props.beforeUpload}
        onRemove={props.onRemove && props.onRemove}
        onPreview={props.onPreview}
        // disabled={isDone}
        {..._uploadProps}
        customRequest={(options) =>
          props.customRequest &&
          props.customRequest(options, note, props.documentUploadFile.docType)
        }
        // itemRender={(item, uplodaFile) => {
        //   console.log('item render', item, uplodaFile)
        //   return uplodaFile.response ? (
        //     <>
        //       {uplodaFile.response.fileName} - {uplodaFile.response.note}
        //     </>
        //   ) : (
        //     <>{uplodaFile.name}</>
        //   )
        // }}
      >
        <Button>
          <UploadOutlined />{' '}
          {props.renderButtonLabel && props.renderButtonLabel(props.documentUploadFile.docType)}
        </Button>
      </Upload>
    </Space>
  )
}
