import { IRealmAuth } from '@nic/commons'

const baseRegistryRoles = [
  {
    subject: 'Request',
    actions: ['Read', 'Update', 'Search'],
  },
  {
    subject: 'CompletedRequest',
    actions: ['Read', 'Write', 'Update', 'Search', 'View'],
  },
  {
    subject: 'PendingRequest',
    actions: ['Read', 'Write', 'Update', 'Search', 'View'],
  },
  {
    subject: 'ApprovedRequest',
    actions: ['Read', 'Write', 'Update', 'Search', 'View'],
  },
  {
    subject: 'RegistrarUpdatedRequest',
    actions: ['Read', 'Write', 'Update', 'Search', 'View'],
  },
  {
    subject: 'RejectedRequest',
    actions: ['Read', 'Write', 'Update', 'Search', 'View'],
  },

  {
    subject: 'SubmittedRequest',
    actions: ['Read', 'Write', 'Update', 'Search', 'View'],
  },
  {
    subject: 'ProcessingExpiredRequest',
    actions: ['Read', 'Write', 'Update', 'Search', 'View'],
  },
  {
    subject: 'ProcessingRequest',
    actions: ['Read', 'Write', 'Update', 'Search', 'View'],
  },
  {
    subject: 'CancelledRequest',
    actions: ['Read', 'Write', 'Update', 'Search', 'View'],
  },
  {
    subject: 'ExpiredRequest',
    actions: ['Read', 'Write', 'Update', 'Search', 'View'],
  },
  {
    subject: 'Registrar',
    actions: ['Read', 'View'],
  },
  {
    subject: 'User',
    actions: ['Read'],
  },
  {
    subject: 'Ability',
    actions: ['Update'],
  },
  {
    subject: 'AllPendingRequest',
    actions: ['View'],
  },
  {
    subject: 'AllRegistrarUpdated',
    actions: ['View'],
  },
  {
    subject: 'ExpiringRequest',
    actions: ['View'],
  },
  {
    subject: 'AccessDarwin',
    actions: ['Permit'],
  },
]

const baseAssignmentRequestAbilities = [
  {
    subject: 'Request',
    actions: ['AssignToMe', 'Release'],
  },
  {
    subject: 'ViewAssignationRequests',
    actions: ['all', 'assignedToMe', 'assigned', 'unassigned'],
  },
  {
    subject: 'ViewRequests',
    actions: [
      'pending',
      'registrarUpdated',
      'completed',
      'processing',
      'processingExpired',
      'expired',
      'completed',
      'processingCancelled',
      'cancelled',
      'all',
    ],
  },
]

export const usersAbilities: IRealmAuth = {
  registry: {
    roles: {
      'darwin-admin': [
        ...baseAssignmentRequestAbilities,
        {
          subject: 'AllPendingRequest',
          actions: ['View'],
        },
        {
          subject: 'AllRegistrarUpdated',
          actions: ['View'],
        },
      ],
      'operator-admin': [
        ...baseRegistryRoles,
        ...baseAssignmentRequestAbilities,
        {
          subject: 'Request',
          actions: ['Read', 'Update', 'Search', 'Delete', 'Edit'],
        },
      ],
      tech: [...baseRegistryRoles, ...baseAssignmentRequestAbilities],
      operator: [...baseRegistryRoles, ...baseAssignmentRequestAbilities],
      'tech-admin': [
        ...baseAssignmentRequestAbilities,
        {
          subject: 'Request',
          actions: ['Read', 'Update', 'Search', 'Delete'],
        },
        {
          subject: 'Request',
          actions: ['Read', 'Update', 'Search', 'Delete'],
        },
        {
          subject: 'Request',
          actions: ['AssignToMe', 'Release', 'AssignToUser'],
        },
      ],
    },
  },
  registrar: {
    roles: {
      admin: [
        {
          subject: 'PendingRequest',
          actions: ['Read', 'Write', 'Update', 'View'],
        },
        {
          subject: 'ApprovedRequest',
          actions: ['Read', 'Write', 'Update', 'View'],
        },
        {
          subject: 'ExpiredRequest',
          actions: ['Read', 'Write', 'Update', 'View'],
        },
        {
          subject: 'ProcessingExpiredRequest',
          actions: ['Read', 'Write', 'Update'],
        },
        {
          subject: 'ProcessingRequest',
          actions: ['Read', 'Write', 'Update'],
        },
        {
          subject: 'RegistrarUpdatedRequest',
          actions: ['Read', 'Write', 'Update', 'View'],
        },
        {
          subject: 'CompletedRequest',
          actions: ['Read', 'Write', 'Update', 'View'],
        },
        {
          subject: 'User',
          actions: ['Read'],
        },
        {
          subject: 'Anomaly',
          actions: ['Modify'],
        },
        {
          subject: 'Request',
          actions: ['AssignToMe', 'Release', 'AssignToUser'],
        },
        {
          subject: 'ViewAssignationRequests',
          actions: ['all', 'assignedToMe', 'assigned', 'unassigned'],
        },
        {
          subject: 'ViewRequests',
          actions: [
            'pending',
            'registrarUpdated',
            'completed',
            'processing',
            'processingExpired',
            'expired',
            'completed',
            'processingCancelled',
            'cancelled',
            'all',
          ],
        },
        {
          subject: 'AllPendingRequest',
          actions: ['View'],
        },
        {
          subject: 'AllRegistrarUpdated',
          actions: ['View'],
        },
        {
          subject: 'ExpiringRequest',
          actions: ['View'],
        },
        {
          subject: 'AccessDarwin',
          actions: ['Permit'],
        },
      ],
      user: [
        {
          subject: 'Request',
          actions: ['Search'],
        },
        {
          subject: 'Anomaly',
          actions: ['Modify'],
        },
      ],
      domain: [
        {
          subject: 'PendingRequest',
          actions: ['Read', 'Write', 'Update', 'Search', 'View'],
        },
        {
          subject: 'ApprovedRequest',
          actions: ['Read', 'Write', 'Update', 'Search', 'View'],
        },
        {
          subject: 'ExpiredRequest',
          actions: ['Read', 'Write', 'Update', 'Search', 'View'],
        },
        {
          subject: 'ProcessingExpiredRequest',
          actions: ['Read', 'Write', 'Update', 'Search'],
        },
        {
          subject: 'ProcessingRequest',
          actions: ['Read', 'Write', 'Update', 'Search'],
        },
        {
          subject: 'RegistrarUpdatedRequest',
          actions: ['Read', 'Write', 'Update', 'Search', 'View'],
        },
        {
          subject: 'CompletedRequest',
          actions: ['Read', 'Write', 'Update', 'Search', 'View'],
        },
        {
          subject: 'User',
          actions: ['Read'],
        },
        {
          subject: 'Anomaly',
          actions: ['Modify'],
        },
        {
          subject: 'Request',
          actions: ['AssignToMe', 'Release'],
        },
        {
          subject: 'ViewAssignationRequests',
          actions: ['assignedToMe', 'unassigned'],
        },
        {
          subject: 'ViewRequests',
          actions: [
            'pending',
            'registrarUpdated',
            'completed',
            'processing',
            'processingExpired',
            'expired',
            'completed',
            'processingCancelled',
            'cancelled',
            'all',
          ],
        },
        {
          subject: 'ExpiringRequest',
          actions: ['View'],
        },
        {
          subject: 'AccessDarwin',
          actions: ['Permit'],
        },
      ],
      darwin: [
        {
          subject: 'PendingRequest',
          actions: ['Read', 'Write', 'Update', 'Search', 'View'],
        },
        {
          subject: 'ApprovedRequest',
          actions: ['Read', 'Write', 'Update', 'Search', 'View'],
        },
        {
          subject: 'ExpiredRequest',
          actions: ['Read', 'Write', 'Update', 'Search', 'View'],
        },
        {
          subject: 'ProcessingExpiredRequest',
          actions: ['Read', 'Write', 'Update', 'Search'],
        },
        {
          subject: 'ProcessingRequest',
          actions: ['Read', 'Write', 'Update', 'Search'],
        },
        {
          subject: 'RegistrarUpdatedRequest',
          actions: ['Read', 'Write', 'Update', 'Search', 'View'],
        },
        {
          subject: 'CompletedRequest',
          actions: ['Read', 'Write', 'Update', 'Search', 'View'],
        },
        {
          subject: 'User',
          actions: ['Read'],
        },
        {
          subject: 'Anomaly',
          actions: ['Modify'],
        },
        {
          subject: 'ViewAssignationRequests',
          actions: [''],
        },
        {
          subject: 'ViewRequests',
          actions: [
            'pending',
            'registrarUpdated',
            'completed',
            'processing',
            'processingExpired',
            'expired',
            'completed',
            'processingCancelled',
            'cancelled',
            'all',
          ],
        },
        {
          subject: 'ExpiringRequest',
          actions: ['View'],
        },
        {
          subject: 'AccessDarwin',
          actions: ['Permit'],
        },
      ],
    },
  },
}
