import { RequestContext } from '../Contexts/RequestContext'
import { RequestExpirationDaysContext } from '../Providers/RequestExpirationDaysProvider'
import { useRequestTimeline } from '../Feedback/RequestProgress/useRequestTimeline'
import { getWindowRunConfig } from '../../darwinConfig'
import { useAssignmentRequest } from '../AssignmentRequests/useAssignmentRequest'
import { useContext } from 'react'

/**
 * Controlla i permessi del Registrar legati alle azioni applicabili ad una richiesta
 * non posso farlo se :
 * - è scaduta la finestra di modifica
 * - se non l'ho presa in carico
 * - se non in certi stati (pending, registrarUpdated)
 */
export function useRegistrarPermissions() {
  const { isRegistrarAssignedUser, isRegistryAssignedUser } = useAssignmentRequest()
  const { stateReqById } = useContext(RequestContext)
  const {
    state: { data },
  } = useContext(RequestExpirationDaysContext)
  const ctxTimeline = useRequestTimeline(
    stateReqById?.data?.created,
    getWindowRunConfig().DARWIN_REQUEST_DURATION,
    data?.count
  )
  const hasRegistrarNotProcessRequestPermission =
    (ctxTimeline && ctxTimeline.percentage >= 100) ||
    stateReqById.data?.status !== 'pending' ||
    !isRegistrarAssignedUser
  const hasRegistryAcceptanceToBeDisabled =
    !isRegistryAssignedUser ||
    (ctxTimeline && ctxTimeline.percentage >= 100) ||
    stateReqById.data?.status !== 'registrarUpdated'
  return { hasRegistryAcceptanceToBeDisabled, hasRegistrarNotProcessRequestPermission }
}
