import { Button } from 'antd'
import React from 'react'
import { Div, Flex } from '@nic/ui-comps'
import CheckCircleOutlined from '@ant-design/icons/lib/icons/CheckCircleOutlined'

export function DescriptionWarningNoChanges(props: {
  values: any
  title: any
  unmodifiedAnomalies: any
  description1: any
  element: (a: string, i: number) => JSX.Element
  description2: any
  onClick: () => void
  submitButtonText: any
  disable: boolean
}) {
  return (
    <Flex
      direction={'column'}
      style={{ rowGap: '20px', color: props.disable ? 'grey' : '' }}
      align={'flex-start'}
    >
      <Flex direction={'column'} align={'flex-start'} justify={'flex-start'}>
        {props.values.noChanges && <Div>{props.title}</Div>}
        {props.unmodifiedAnomalies.length > 0 && (
          <>
            <Div marginTop={'15px'}>{props.description1}</Div>
            <Flex direction={'column'} align={'flex-start'} justify={'flex-start'}>
              {props.unmodifiedAnomalies.map(props.element)}
            </Flex>
            {!props.disable && (
              <Div marginTop={'20px'} fontWeight={'700'} fontSize={'1.3rem'}>
                {props.description2}
              </Div>
            )}
          </>
        )}
      </Flex>
      <Flex direction={'row'} align={'flex-end'} justify={'flex-end'} style={{ width: '100%' }}>
        {!props.disable ? (
          <Button size="small" type="primary" onClick={props.onClick} disabled={props.disable}>
            {props.submitButtonText}
          </Button>
        ) : (
          <CheckCircleOutlined style={{ fontSize: '20pt', color: '#389E0E' }} />
        )}
      </Flex>
    </Flex>
  )
}
