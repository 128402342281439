import React from 'react'
import { ReadsValidationStatus } from '@nic/nic-api/types/Darwin/types'
import { NicScope } from '@nic/nic-api/types'
import { RegistrantUpdatedData } from '../../RegistrantLoader/RegistrantUpdatedData'
import { FactoryContactCard } from '../../DataDisplay/RegistrantContactCards/FactoryContactCard'
import { ReadsValidationStatusBadge } from './ReadsValidationStatusBadge'

/**
 * Mostra il Dettaglio contatto con il feedbackReads
 * @param readsValidationStatus
 * @param scope
 * @param requestId
 * @constructor
 */
export const RegistrantUpdatedWithReadsFeedback: React.FC<{
  readsValidationStatus: ReadsValidationStatus
  scope: NicScope
  contactUpdateId: number
}> = ({ readsValidationStatus, scope, contactUpdateId }) => {
  return (
    <ReadsValidationStatusBadge
      status={readsValidationStatus}
      scope={scope}
      contactUpdateId={contactUpdateId}
    >
      <RegistrantUpdatedData>
        {(data) => <FactoryContactCard type={'updated'} {...data} />}
      </RegistrantUpdatedData>
    </ReadsValidationStatusBadge>
  )
}
