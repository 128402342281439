/**
 *
 * @param {string} dateString
 * @param {Intl.DateTimeFormatOptions} options
 * @param {string} locale
 * @returns {string}
 *
 * Funzione di utilità che permette di formattare una stringa rappresentante una
 * data (formato iso..) in una stringa secondo un locale specificato (default it-IT)
 * e ad un parametro (options) che permette di definire i campi e il formato
 * (long, numeric, 2-digits per esempio)
 */
export const formatDate = (
  dateString: string,
  options: Intl.DateTimeFormatOptions,
  locale = 'it-IT'
): string => {
  const date = new Date(dateString)
  let res = ''
  try {
    res = new Intl.DateTimeFormat(locale, options).format(date)
  } catch (e) {
    console.error(new Error('formatDate error: ' + date))
  }
  return res
}

export function stdDateHelper(created?: string) {
  if (created && checkValidDate(created)) {
    return formatDate(created, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    } as Intl.DateTimeFormatOptions)
  } else return undefined
}

function checkValidDate(date: string) {
  const timestamp = Date.parse(date)
  return !isNaN(timestamp)
}
