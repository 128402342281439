import React, { useContext } from 'react'
import { AuthContext } from '@nic/kc-auth'
import { RequestContext } from '../Contexts/RequestContext'
import { userDarwinRole } from '../Layout/UserInfo'

export function useAssignmentRequest() {
  const { keycloak } = React.useContext(AuthContext)
  const { stateReqById } = useContext(RequestContext)
  let userRole = userDarwinRole(keycloak)

  const isRegistrarAssignedUser =
    keycloak?.tokenParsed?.userId === stateReqById?.data?.registrarUser?.id
  const isRegistrarAssigned = !stateReqById?.data?.registrarUser?.id

  const isRegistryAssignedUser =
    keycloak?.tokenParsed?.userId === stateReqById?.data?.registryUser?.id
  const isRegistryAssigned = stateReqById?.data?.registryUser?.id !== undefined

  const isUserDomain = userRole === 'domain'
  const isUserAdmin = userRole === 'admin' || userRole === 'tech-admin'
  const isUserDarwin = userRole === 'darwin'
  const isUserDarwinAdmin = userRole === 'darwin-admin'

  const registrarStatus: 'assignedTo' | 'assignedToMe' | undefined = isRegistrarAssignedUser
    ? 'assignedToMe'
    : isRegistrarAssigned
    ? 'assignedTo'
    : undefined

  const registryStatus: 'assignedTo' | 'assignedToMe' | undefined = isRegistryAssignedUser
    ? 'assignedToMe'
    : isRegistrarAssigned
    ? 'assignedTo'
    : undefined

  return {
    /** è assegnata ad un utente Registrar **/
    isRegistrarAssigned,
    /** è assegnata ad un utente Registrar e quello attuale coincide **/
    isRegistrarAssignedUser,
    /** è assegnata ad un utente Registry e quello attuale coincide **/
    isRegistryAssignedUser,
    /** è assegnata ad un utente Registry **/
    isRegistryAssigned,
    userRole,
    isUserDarwin,
    isUserAdmin,
    isUserDomain,
    isUserDarwinAdmin,
    registrarStatus,
    registryStatus,
  }
}
