import React from 'react'
import { ModalProps } from 'antd/lib/modal/Modal'
import { NicScope } from '@nic/nic-api/types'
import { useTranslation } from '@nic/commons'
import { IAnomaly } from '@nic/nic-api/types/Darwin/types'
import { Modal, Table } from 'antd'
import { RequestContactUpdateAnomaliesService } from '@nic/nic-api'
// import { printAxiosFetchError } from '../../ErrorAlert/ErrorAlert'
import { PrintAnomalyList } from '../../Anomalies/PrintAnomalyList'
import { printAxiosFetchError } from '../../ErrorAlert/ErrorAlert'

export const ModalReadsValidationStatus: React.FC<
  ModalProps & { contactUpdateId: number; scope: NicScope }
> = (props) => {
  const t = useTranslation()
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState([])
  const [error, setError] = React.useState<any>()

  React.useEffect(() => {
    const fetch = () => {
      RequestContactUpdateAnomaliesService(props.contactUpdateId, props.scope)
        .then((resp) => {
          setLoading(false)
          setData(resp.data)
        })
        .catch((e) => setError(e))
    }

    props.visible && fetch()
  }, [props.visible, props.scope, props.contactUpdateId])

  return (
    <Modal {...props}>
      {error && <>{printAxiosFetchError(error)}</>}
      {!error && (
        <Table dataSource={data as Array<IAnomaly>} pagination={false} loading={loading}>
          <Table.Column<string>
            title={t('fields')}
            dataIndex={'field'}
            key={'field'}
            render={(_) => t(`label.${_}`)}
          />
          <Table.Column<Array<number>>
            title={t('issues')}
            // title={`${t('datatable.contactId')}`}
            dataIndex={'codes'}
            key={'codes'}
            render={(_) => PrintAnomalyList(_ as any, t)}
          />
        </Table>
      )}
    </Modal>
  )
}
