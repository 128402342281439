import { Darwin } from '@nic/nic-api/types'
export function documentUploadFile2DarwinUploadedFileResponse(
  documents: DocumentUploadFile<Darwin.DarwinUploadedFileResponse>[]
): Darwin.DarwinUploadedFileResponse[] {
  return (
    documents
      .map((docTypeList) =>
        docTypeList?.fileList?.map((docTypeItem) => {
          return {
            id: docTypeItem?.response?.id,
            documentType: docTypeList.docType,
            name: docTypeItem.name,
            note: docTypeItem.response?.note,
            fileName: docTypeItem.response?.fileName,
            fileType: docTypeItem.response?.fileType,
            fileSize: docTypeItem.response?.fileSize,
          } as Darwin.DarwinUploadedFileResponse
        })
      )
      .reduce((result, item) => {
        // @ts-ignore
        return [...result, ...item]
      }, [] as Darwin.DarwinUploadedFileResponse[]) || []
  )
}
