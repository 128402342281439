import { Button, message, Popconfirm, Popover } from 'antd'
import React from 'react'
import { useTranslation } from '@nic/commons'

export function ActionButtonHold(props: { onClick: () => void; disabled?: boolean }) {
  const t = useTranslation()
  const handleOnClick = () => {
    try {
      props.onClick()
      message.info(t('actionButtons.hold.clicked'))
    } catch (e) {
      message.error(t('some thing was wrong ' + JSON.stringify(e)))
    }
  }
  return (
    <Popconfirm
      placement="top"
      title={t('actionButtons.hold.warning')}
      onConfirm={handleOnClick}
      okText="Yes"
      cancelText="No"
      color={'rgb(249, 227, 228)'}
      cdata-cy={'popconfirm-delete'}
      overlayStyle={{ maxWidth: 350 }}
      // color={'red'}
    >
      <Button type={'link'} key={'hold'} disabled={props.disabled}>
        <Popover content={t('actionButtons.hold.tip')}>{t('actionButtons.hold.title')}</Popover>
      </Button>
    </Popconfirm>
  )
}

export function ActionButtonDelete(props: { onClick: () => void; disabled?: boolean }) {
  const t = useTranslation()
  const confirm = () => {
    try {
      props.onClick()
      message.info(t('actionButtons.delete.clicked'))
    } catch (e) {
      message.error(t('some thing was wrong ' + JSON.stringify(e)))
    }
  }

  return (
    <Popconfirm
      placement="top"
      title={t('actionButtons.delete.warning')}
      onConfirm={confirm}
      okText="Yes"
      cancelText="No"
      color={'rgb(249, 227, 228)'}
      overlayStyle={{ maxWidth: 350 }}
      cdata-cy={'popconfirm-delete'}
    >
      <Button type={'link'} key={'delete'} disabled={props.disabled}>
        <Popover content={t('actionButtons.delete.tip')}>{t('actionButtons.delete.title')}</Popover>
      </Button>
    </Popconfirm>
  )
}

export function ActionButtonUpdate(props: { onClick: () => void; disabled?: boolean }) {
  const t = useTranslation()
  const handleOnClick = () => {
    try {
      props.onClick()
      message.info(t('actionButtons.update.clicked'))
    } catch (e) {
      message.error(t('some thing was wrong ' + JSON.stringify(e)))
    }
  }

  return (
    <Button type={'link'} key={'update'} onClick={handleOnClick} disabled={props.disabled}>
      <Popover content={t('actionButtons.update.tip')}>{t('actionButtons.update.title')}</Popover>
    </Button>
  )
}
