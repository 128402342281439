import { Button, notification, Popconfirm, Space, Tabs } from 'antd'
import { ContactsToUpdateTab } from './Tabs/ContactsToUpdateTab'
import { ContactsToHoldTab } from './Tabs/ContactsToHoldTab'
import { ContactsToDeleteTab } from './Tabs/ContactsToDeleteTab'
import React, { useContext } from 'react'
import { Div, PaperLayout, VerticalLayout } from '@nic/ui-comps'
import { ContactsContext } from '../../../../../Providers/ContactsProvider'
import { RequestContext } from '../../../../../Contexts/RequestContext'
import { Darwin, NicScope } from '@nic/nic-api/types'
import { useRouter, useTranslation } from '@nic/commons'
import { PaperTitle } from '../../../../../Layout/PaperTitle'
import { useAssignmentRequest } from '../../../../../AssignmentRequests/useAssignmentRequest'

export type ContactsTabType = 'toUpdate' | 'toHold' | 'toDelete'

export function ContactSelection() {
  const { id, scope, stateReqById } = useContext(RequestContext)
  const {
    state: { error, loading },
    actions,
    dispatch,
  } = useContext(ContactsContext)
  const router = useRouter()
  const [action, setAction] = React.useState<Darwin.ContactActions | undefined>(undefined)
  const { isRegistrarAssignedUser } = useAssignmentRequest()
  const handleOnChangeAllAction = (_action: Darwin.ContactActions) => {
    setAction(_action)
    actions.changeContactsAction(id, _action, scope as NicScope)(dispatch)
  }
  const t = useTranslation()

  const allContactsAction: Darwin.ContactActions[] = ['todo', 'hold', 'delete']
  const colorButtons = { todo: '#52C41A', delete: '#FA541B', hold: '' }
  const [activeKeyTab, setActiveKyTab] = React.useState<ContactsTabType>('toUpdate')
  const onChangeHandler = (activeKey: string) => {
    setActiveKyTab(activeKey as ContactsTabType)
    router.replaceHelper({ contactTab: activeKey, page: 0, size: 5 })
  }

  const hasToShowContactSelection =
    stateReqById.data?.status &&
    !['completed', 'registrarUpdated'].includes(stateReqById.data?.status) &&
    scope !== 'registry'

  React.useEffect(() => {
    if (router.query.hasOwnProperty('contactTab') && router.query.tab !== activeKeyTab) {
      setActiveKyTab(router.query.contactTab as ContactsTabType)
    }
  }, [activeKeyTab, router.query])

  return (
    <PaperLayout>
      <PaperTitle showIcon title={t('contactSelectionTab.title')} />
      {error && notification.error({ message: error.message })}
      <p>{t('contactSelectionTab.description_p')}</p>
      <ul>
        <li>
          <b>{t('contactSelectionTab.li1_strong')}</b>
          {t('contactSelectionTab.li1_content')}
        </li>
        <li>
          <b>{t('contactSelectionTab.li2_strong')}</b> {t('contactSelectionTab.li2_content')}
        </li>
        <li>
          <b>{t('contactSelectionTab.li3_strong')}</b> {t('contactSelectionTab.li3_content')}
        </li>
      </ul>
      {hasToShowContactSelection && (
        <PaperLayout>
          <VerticalLayout>
            <Div textAlign={'center'}>
              <p>
                Sposta <b>TUTTI</b> i contatti nella lista:
              </p>
            </Div>
            <Space>
              {allContactsAction.map((act, index) => {
                if (['delete', 'hold'].includes(act)) {
                  return (
                    <Popconfirm
                      placement="top"
                      title={t('actionButtons.' + act + '.warningAllButton')}
                      onConfirm={() => handleOnChangeAllAction(act)}
                      okText="Yes"
                      cancelText="No"
                      color={'rgb(249, 227, 228)'}
                      overlayStyle={{ maxWidth: 450 }}
                      cdata-cy={'popconfirm-delete'}
                    >
                      <Button
                        style={{ backgroundColor: colorButtons[act], border: 0 }}
                        disabled={!isRegistrarAssignedUser}
                        key={index}
                        shape="round"
                        type={'primary'}
                        loading={action === act && loading}
                      >
                        {t(`contactSelection.button.${act}`)}
                      </Button>
                    </Popconfirm>
                  )
                }
                return (
                  <Button
                    style={{ backgroundColor: colorButtons[act], border: 0 }}
                    disabled={!isRegistrarAssignedUser}
                    key={index}
                    shape="round"
                    type={'primary'}
                    onClick={() => handleOnChangeAllAction(act)}
                    loading={action === act && loading}
                  >
                    {t(`contactSelection.button.${act}`)}
                  </Button>
                )
              })}
            </Space>
          </VerticalLayout>
        </PaperLayout>
      )}
      <Tabs type={'card'} activeKey={activeKeyTab} onChange={onChangeHandler}>
        {ContactsToUpdateTab({ tabKey: 'toUpdate' })}
        {ContactsToHoldTab({ tabKey: 'toHold' })}
        {ContactsToDeleteTab({ tabKey: 'toDelete' })}
      </Tabs>
    </PaperLayout>
  )
}
