import { useContext } from 'react'
import { RequestContext } from '../../../Contexts/RequestContext'
import { RequestExpirationDaysContext } from '../../../Providers/RequestExpirationDaysProvider'
import { useRequestTimeline } from '../../../Feedback/RequestProgress/useRequestTimeline'
import { useRequestInfo } from '../../../Hooks/useRequestInfo'
import { Flex, HorizontalLayout } from '@nic/ui-comps'
import { NicScopeHandler } from '../../../Various/NicScopeHandler/NicScopeHandler'
import { SummaryRequestTabProp } from './index'
import { RegistrarsCard, RegistrarsCardProps } from './RegistrarsCard/RegistrarsCard'
import { EventListCard } from '../../../Cards/TimelineRequestCard/EventListCard'
import { VariationProposalByRegistrarCard } from '../../../Cards/VariationProposalByRegistrarCard'
import { AbilityContext, camelToDash, useTranslation } from '@nic/commons'
import { DocumentsCard } from '../../../Cards/DocumentsCard'
import { Collapse } from 'antd'
import { sortBy } from 'lodash'
import { IRequestEvent } from '@nic/nic-api/types/Darwin/types'
import { DarwinEvent } from '../../../Events/DarwinEvent'
import { VariationProposalAcceptanceCard } from '../../../Cards/VariationProposalAcceptanceCard/VariationProposalAcceptanceCard'
import { RequestManagementCard } from '../../../Cards/RequestManagementCard'
import { useAssignmentRequest } from '../../../AssignmentRequests/useAssignmentRequest'
import { getWindowRunConfig } from '../../../../darwinConfig'
import { useRegistrarPermissions } from '../../../Hooks/useRegistrarPermissions'

const { Panel } = Collapse
/**
 * Contenuto della visualizzazione della Request  summary
 * @param props
 * @constructor
 */
export const SummaryRequestTabContent: React.FC<
  SummaryRequestTabProp & RegistrarsCardProps & { hasDocuments?: boolean }
> = (props) => {
  const { stateReqById, stateReqAnomalies, stateReqContacts, stateReqDomains } =
    useContext(RequestContext)
  const {
    state: { data },
  } = useContext(RequestExpirationDaysContext)
  const ctxTimeline = useRequestTimeline(
    stateReqById?.data?.created,
    getWindowRunConfig().DARWIN_REQUEST_DURATION,
    data?.count
  )
  const t = useTranslation()
  const { hasExpirationDays } = useRequestInfo()
  const { isRegistrarAssignedUser } = useAssignmentRequest()
  const { hasRegistrarNotProcessRequestPermission, hasRegistryAcceptanceToBeDisabled } =
    useRegistrarPermissions()
  const ability: any = useContext(AbilityContext)

  const handleReasons = () => {
    const messagges: string[] = []

    if (ctxTimeline && ctxTimeline.percentage >= 100) {
      messagges.push(t('message.warning.expireGt100percentage'))
    }

    if (stateReqById.data?.status !== 'pending') {
      messagges.push(t('message.warning.stateNotCompatible'))
    }

    if (!isRegistrarAssignedUser) {
      messagges.push(t('message.warning.notAssignedUser'))
    }
    return messagges
  }

  return (
    <div className={camelToDash(SummaryRequestTabContent.name)}>
      {/* Mostra Last event message */}
      <LastEventMessageCard />
      <RegistrarsCard
        onChangeTab={props.onChangeTab}
        isRegistrarUpdatedStatus={props.isRegistrarUpdatedStatus}
        hasExpirationDays={hasExpirationDays}
        tabKey={props.tabKey}
      />
      <HorizontalLayout
        style={{
          alignItems: 'stretch',
          justifyContent: 'flex-start',
          columnGap: '20px',
          padding: '0 10px 0 10px',
          backgroundColor: '#FAFAFA',
        }}
      >
        {/*  Card Eventi*/}
        <EventListCard created={stateReqById?.data?.created} />
        {/*  Card Documenti*/}
        {<DocumentsCard />}
        {/*  Proposta di modifica */}
        <NicScopeHandler
          registry={
            <Flex direction={'column'} width={'100%'}>
              <VariationProposalAcceptanceCard
                onEdit={props.onEdit}
                edit={props.edit}
                disabled={hasRegistryAcceptanceToBeDisabled}
                anomalies={stateReqAnomalies.data}
                contacts={stateReqContacts.data}
                domains={stateReqDomains.data}
              />

              {(ability.can('Edit', 'Request') || ability.can('Delete', 'Request')) && (
                <RequestManagementCard />
              )}
            </Flex>
          }
          registrar={
            <VariationProposalByRegistrarCard
              onEdit={props.onEdit}
              disabledEditButton={props.edit}
              disabledButtons={hasRegistrarNotProcessRequestPermission}
              reasons={handleReasons()}
            />
          }
        />
      </HorizontalLayout>
    </div>
  )
}

/**
 * Se necessario, a secondo di varie condizioni, mostra il messaggio dell'ultimo evento
 * @constructor
 */
const LastEventMessageCard: React.FC = () => {
  const { stateReqEvents } = useContext(RequestContext)
  const { data } = stateReqEvents
  const t = useTranslation()
  const sortEvents = (events: IRequestEvent[]) => {
    return sortBy(events, 'created').reverse()
  }
  const event = data?.elements && sortEvents(data.elements)[0]

  const compatibleStates = [
    'registrarUpdated',
    'pending',
    'registryUpdated',
    'registrarUpdateRejected',
    'updatedRequiredDocuments',
  ]

  const hasToBeShown = event && compatibleStates.includes(event.type) && event.message
  return (
    <div style={{ padding: 10 }}>
      {hasToBeShown && (
        <LastEventMessageCollapse title={t('contactLastEventMessage')} event={event} />
      )}
    </div>
  )
}

const LastEventMessageCollapse: React.FC<{ title: string; event: IRequestEvent }> = ({
  title,
  event,
}) => (
  <Collapse collapsible="header" defaultActiveKey={['1']}>
    <Panel header={<b>{title}</b>} key="1">
      {event && <DarwinEvent event={event} />}
    </Panel>
  </Collapse>
)
