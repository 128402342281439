import { Darwin, Domains } from '@nic/nic-api/types'
import { IDarwinContactExtended } from '../ContactListFullDetailed/ContactListFullDetailed'
import {
  AnomalyAssociationType,
  ContactReportContent,
  ContactReportType,
} from '../DataDisplay/RegistrantContactCards/types'
import { IContactUpdateContactData } from '../../../../nic-react-monorepo/packages/nic-api/types/Darwin/types'

const anomalyGroups: AnomalyAssociationType = {
  postalCode: 'address',
  city: 'address',
  streets: 'address',
  state: 'address',
}

/***
 *
 * @param contact
 * @param contactUpdate
 * @param contactAnomalies
 */
export function contactAnomaliesUpdatesBuilder(
  contact?: (Darwin.ReferenceContact & Domains.Contact) | IDarwinContactExtended | Domains.Contact,
  contactUpdate?: Darwin.ContactUpdate,
  contactAnomalies?: Darwin.Anomalies
): ContactReportType | {} {
  console.log(contactAnomaliesUpdatesBuilder.name, contact, contactUpdate, contactAnomalies)
  if (contact) {
    return darwinContactReportBuilder(
      contact,
      anomalyGroups,
      contactAnomalies || [],
      contactUpdate?.contactData
    )
  }
  return {}
}

/**
 *
 * @param contactUpdate
 * @param contactAnomalies
 */
export function contactUpdatesBuilder(
  contactUpdate?: Darwin.ContactUpdate,
  contactAnomalies?: Darwin.Anomalies
): ContactReportType | {} {
  if (contactUpdate && contactAnomalies) {
    return darwinContactReportBuilder(
      contactUpdate.contactData,
      anomalyGroups,
      contactAnomalies,
      contactUpdate.contactData
    )
  }
  return {}
}

/**
 * Costruisce un oggetto con proprietà, anomalie e modifiche apportate ad ogni campo
 * @param seedObject
 * @param anomalyAssociations
 * @param contactAnomalies
 * @param contactUpdate
 */
export function darwinContactReportBuilder(
  /** oggetto da analizzare **/
  seedObject:
    | (Darwin.ReferenceContact & Domains.Contact)
    | IContactUpdateContactData
    | Domains.Contact
    | IDarwinContactExtended,
  /** associazione delle anomalie con eventuali gruppi **/
  anomalyAssociations: AnomalyAssociationType,
  /** anomalie riscontrate nel contatto **/
  contactAnomalies: Darwin.Anomalies,
  /** aggiornamenti associati ai contatti **/
  contactUpdate?: IContactUpdateContactData
): any {
  let flatten: ContactReportContent | {} = {}
  let plainProps: ContactReportContent | {} = {}
  //console.debug("[" + darwinContactReportBuilder.name + " log]");
  // console.group("Inputs:");

  //console.debug("seedObject", seedObject);
  //console.debug("contactAnomalies", contactAnomalies);
  //console.debug("contactUpdate", contactUpdate);

  Object.entries(seedObject).forEach(([field, value]) => {
    // riporta se esiste un eventuale gruppo associato all fieldKey dell'anomalia
    const associatedAnomalyGroup = anomalyAssociations[field as keyof typeof anomalyAssociations]
    const anomalyField = associatedAnomalyGroup ? associatedAnomalyGroup : field

    const anomalies = contactAnomalies.find((obj: { field: string }) => {
      return obj.field === anomalyField
    })

    /*
                            Rende flat l'oggetto,
                            cioè se ci sono proprietà oggetti li segue in maniera ricorsiva
                            (ad esempio nel caso della proprietà Registrar)
                         */
    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      // console.debug(
      //   "isObject:",
      //   "value",
      //   value,
      //   "contacUpdate",
      //   contactUpdate && contactUpdate[field as keyof typeof contactUpdate]
      //     ? contactUpdate[field as keyof typeof contactUpdate]
      //     : undefined
      // );

      flatten = {
        ...flatten,
        ...darwinContactReportBuilder(
          value,
          anomalyAssociations,
          contactAnomalies,
          // @ts-ignore
          contactUpdate && contactUpdate[field as keyof typeof contactUpdate]
            ? contactUpdate[field as keyof typeof contactUpdate]
            : undefined
        ),
      }
    } else {
      // console.debug(
      //   "plainProps:",
      //   "field",
      //   field,
      //   "value:",
      //   contactUpdate
      //     ? contactUpdate[field as keyof typeof contactUpdate]
      //     : undefined
      // );
      plainProps = {
        ...plainProps,
        // Crea l'oggetto
        [field]: {
          defaultValue: value,
          update: contactUpdate ? contactUpdate[field as keyof typeof contactUpdate] : undefined,
          anomalies: anomalies && anomalies,
          group: anomalyField,
        } as ContactReportContent,
      }
    }
  })
  // console.groupEnd();
  // console.group("Outputs:");
  // console.debug("Flatten", { ...flatten, ...plainProps });
  // console.groupEnd();
  return { ...flatten, ...plainProps }
}
