import { Button, Tag, Tooltip } from 'antd'
import React from 'react'
import { useRouter, useTranslation } from '@nic/commons'
import { useScopeInfo } from '../../Hooks/useScopeInfo'
import { AssignmentRequestActionsProps } from '../ButtonsAssignmentRequestActions'
import { useAssignRequestToMe } from './useAssignRequestToMe'
import { printAssignmentRequestActionError } from './printAssignmentRequestActionError'
import { UserAddOutlined } from '@ant-design/icons'
import { stringify } from 'qs'
import { useAssignmentRequest } from '../useAssignmentRequest'

export function ButtonAssignToMe(props: AssignmentRequestActionsProps) {
  const t = useTranslation()
  const router = useRouter()
  const { scope } = useScopeInfo()
  const { loading, error, handleAssignRequestToMe } = useAssignRequestToMe()
  const {
    isRegistrarAssignedUser,
    isRegistrarAssigned,
    isUserDomain,
    isRegistryAssignedUser,
    isRegistryAssigned,
  } = useAssignmentRequest()
  const hasNoPermission =
    scope === 'registrar'
      ? props.isAssignedUser || isRegistrarAssignedUser || (isRegistrarAssigned && isUserDomain)
      : props.isAssignedUser || isRegistryAssignedUser || isRegistryAssigned
  if (error) {
    return <Tag color={'red'}>{printAssignmentRequestActionError({ error })}</Tag>
  }

  const handleOnClick = () => {
    handleAssignRequestToMe(props.requestId, scope).then(() => {
      if (props.onSuccess) {
        props.onSuccess && props.onSuccess()
      } else {
        router.history.push(
          router.pathname + '?' + stringify({ ...router.query, reload: new Date() })
        )
      }
    })
  }

  return (
    <Tooltip title={t('assignmentRequests.action.assignToMe')}>
      <Button
        icon={<UserAddOutlined />}
        type={'link'}
        disabled={hasNoPermission}
        loading={loading}
        onClick={handleOnClick}
      />
    </Tooltip>
  )
}
